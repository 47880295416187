<template>
    <div>
        <!--返回按钮-->
        <div class="div_box_nav">
            <van-nav-bar :title="name" :fixed="true" :placeholder="true" left-arrow
                         @click-left="onClickLeft"/>
        </div>
        <HbiBuild :formTemplate="jsonData" :models="models" ref="hbiBuild"/>
    </div>
</template>
<script>
    import HbiBuild from '@/components/hbi/hbi-build/index'
    import {getInfoById as getById} from '@/api/hbi/report.js'
    import {NavBar} from 'vant';

    export default {
        setup() {
            const onClickLeft = () => history.back();
            return {
                onClickLeft,
            };
        },
        components: {
            HbiBuild,
            [NavBar.name]: NavBar
        },
        data() {
            return {
                jsonData: {},
                id: '',
                models: {},
                name: ''
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            if (!this.id) {
                this.id = this.$route.params.id
            }
            this.init()
        },
        methods: {
            init() {
                getById(this.id).then(({data}) => {
                    if (data && data.code === 0) {
                        this.name = data.data.name
                        //判断当前系统日期是否大于截止日期，若是，则提示访问时间过期
                        /* if (data.data.systemDate != '' && data.data.showEndTime != '') {
                             let date = new Date(data.data.systemDate)
                             let date2 = new Date(data.data.showEndTime)
                             if (date > date2) {
                                 this.$alert('已超过展示截止日期，无法预览。', '提示', {
                                     confirmButtonText: '确定'
                                 });
                                 return;
                             }
                         }*/
                        // 取得表单
                        const formModel = data.data.templateData
                        const forms = formModel ? JSON.parse(formModel) : null
                        this.jsonData = forms
                        this.$nextTick(() => {
                            this.$refs.hbiBuild.initModelKey(true)
                        })

                        if (data.data.reportType == 2) {
                            // 如果是app 则刷新title
                            const name = data.data.name

                            document.title = name
                        }


                    } else {
                        this.id = ''
                    }

                })
            }
        }
    }
</script>
<style lang="scss">
    .div_box_nav {
        .van-nav-bar .van-icon {
            color: #000000;

        }
    }

</style>